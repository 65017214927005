import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { io, Socket } from "socket.io-client";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Objective } from "../../components/app/Objectives/types";

const SOCKET_URL = `https://${process.env.VUE_APP_BACKEND_PATH}.com`;
const SOCKET_PATH = "/api/socket.io";

@Module
export default class SocketModule extends VuexModule {
  socket: Socket | null = null;
  connected = false;
  updateFromSocket = false;
  roomId = "";

  get getSocket(): Socket | null {
    return this.socket;
  }

  get getUpdateFromSocket(): boolean {
    return this.updateFromSocket;
  }

  @Action
  async [Actions.SOCKET_JOIN](organizationId: string) {
    if (this.socket && this.connected) {
      if (organizationId) {
        this.context.commit(Mutations.SET_ROOM, organizationId);
        this.socket.emit("JOIN_ROOM", organizationId);
      }
    }
  }

  @Action
  async [Actions.SEND_CHANGE_OBJECTIVE](objective: Objective[]) {
    if (this.socket && this.connected && this.roomId != "") {
      this.socket.emit("SEND_CHANGE_OBJECTIVE", {
        roomId: this.roomId,
        objective: JSON.stringify(objective)
      });
    }
  }

  @Action
  async [Actions.SEND_CHANGE_DESCRIPTION]() {
    if (this.socket && this.connected && this.roomId != "") {
      this.socket.emit("SEND_CHANGE_DESCRIPTION", {
        roomId: this.roomId
      });
    }
  }

  @Action
  async [Actions.CONNECT_SOCKET]() {
    if (!this.socket) {
      const socket = io(SOCKET_URL, {
        path: SOCKET_PATH
      });

      socket.on("CHANGE_DESCRIPTION", () => {
        // Lógica para manejar el evento CHANGE_DESCRIPTION
      });

      socket.on("CHANGE_OBJECTIVE", async  (objective: string) => {
        const obj = JSON.parse(objective)
        const periodId = this.context.getters.getCycle.id;
        if (obj[0]?.period === periodId) {
          this.context.commit(Mutations.SET_UPDATE_FROM_SOCKET, true);
          await this.context.commit(Mutations.SET_OBJECTIVE, {objective: obj, socketChange: true});
          this.context.commit(Mutations.SET_UPDATE_FROM_SOCKET, false);
        }
      });

      // Ejecutar función después de conectar
      socket.on("connect", () => {
        this.context.commit(Mutations.SET_CONNECTED, true);
        // this.context.dispatch(Actions.SOCKET_JOIN);
      });

      this.context.commit(Mutations.SET_SOCKET, socket);
    }
  }

  @Mutation
  [Mutations.SET_SOCKET](socket: Socket) {
    this.socket = socket;
  }

  @Mutation
  [Mutations.SET_UPDATE_FROM_SOCKET](status: boolean) {
    this.updateFromSocket = status;
  }

  @Mutation
  [Mutations.SET_CONNECTED](status: boolean) {
    this.connected = status;
  }

  @Mutation
  [Mutations.SET_ROOM](roomId: string) {
    this.roomId = roomId;
  }
}
