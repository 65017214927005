import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import SocketModule from "@/store/modules/SocketModule";
import AuthModule from "@/store/modules/AuthModule";
import NotesModule from "@/store/modules/NotesModule";
import CommitmentModule from "@/store/modules/CommitmentModule";
import LogsModule from "@/store/modules/LogsModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ObjectiveModule from "@/store/modules/ObjectiveModule";
import OrganizationModule from "@/store/modules/OrganizationModule";
import TaskModule from "@/store/modules/TaskModule";
import MembersModule from "@/store/modules/MembersModule";
import PeriodModule from "@/store/modules/PeriodModule";

config.rawError = true;

const store = createStore({
  modules: {
    SocketModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ObjectiveModule,
    OrganizationModule,
    TaskModule,
    MembersModule,
    NotesModule,
    LogsModule,
    CommitmentModule,
    PeriodModule
  }
});

export default store;
