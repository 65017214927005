import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class TaskModule extends VuexModule {
  logs = [];
  fetching = false;

  get getLogs(): Array<{}> {
    return this.logs;
  }

  get getLogsFetching(): boolean {
    return this.fetching;
  }

  // Action
  @Action
  [Actions.FETCH_LOGS](objectiveId) {
    this.context.commit(Mutations.SET_LOGS_FETCHING, true);
    const currentToken = JwtService.getToken();

    const url =
      `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okr-logs/` +
      objectiveId;

    if (currentToken != null && objectiveId != null) {
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 400) {
            throw new Error("generalError");
          }
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(data => {
          const { logs } = data;

          if (!logs || !logs.length) {
            this.context.commit(Mutations.SET_LOGS, []);
            return;
          }
          this.context.commit(Mutations.SET_LOGS, logs);
        })
        .catch(error => {
          console.log("[error]", error);
        });
    }
  }

  // Mutation

  @Mutation
  [Mutations.SET_LOGS](payload) {
    this.logs = payload;
    this.fetching = false;
  }

  @Mutation
  [Mutations.SET_LOGS_FETCHING](payload) {
    this.fetching = payload;
  }
}
