import JwtService from "@/core/services/JwtService";
import i18n from "@/core/plugins/i18n";


function translate(key, params, locale = 'es') {
  const originalLocale = i18n.global.locale.value; // Guarda el idioma original
  i18n.global.locale.value = locale; // Cambia el idioma global al deseado
  const translation = i18n.global.t(key, params); // Obtiene la traducción
  i18n.global.locale.value = originalLocale; // Restablece el idioma original
  return translation;
}

const padTo2Digits = num => {
  return num.toString().padStart(2, "0");
};

const format_date = value => {
  const date = new Date(value);
  if (value) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate())
      ].join("-") +
      " " +
      [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(":")
    );
  }
  return "";
};

async function fetchLoginLogs(userId, token) {
  const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/logs?userId=${userId}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token
      }
    });
    const data = await response.json();
    return data.result.logs;
  } catch (error) {
    console.error("Error fetching login logs:", error);
    return []; // Retornamos un array vacío en caso de error
  }
}

const mautic = {
  initialize: mtcPath => {
    if (!mtcPath) {
      return console.log("[mt]", "Unable to resolve mautic script"); // eslint-disable-line no-console
    }

    /* eslint-disable */
    (function (w, d, t, u, n, a, m) {
      w["MauticTrackingObject"] = n;
      w[n] =
        w[n] ||
        function () {
          (w[n].q = w[n].q || []).push(arguments);
        };
      a = d.createElement(t);
      m = d.getElementsByTagName(t)[0];
      a.async = 1;
      a.src = u;
      m.parentNode.insertBefore(a, m);
    })(window, document, "script", mtcPath, "mt");
    /* eslint-enable */

    if (!window.mt) {
      console.log("[mt]", "Unable to initialize mautic");
    }

    return true;
  },

  pageView: (options, log) => {
    if (!window.mt) {
      return console.log(
        "[mt]",
        "Unable to send page view, mautic not initialized"
      );
    }

    log && console.log(`mautic options`, options);

    window.mt(
      "send",
      "pageview",
      { ...options },
      {
        onload: function () {
          if (!window.MauticJS) return setTimeout(onload, 250);

          //Register page tracking event in mautic

          /*
          ##CORRECCION##
          https://forum.mautic.org/t/dynamic-web-content-is-not-loading-for-the-first-page-load-for-an-anonymous-user/22406/23
          */
          window.MauticJS.onFirstEventDelivery(function () {
            window.MauticJS.replaceDynamicContent();
            console.log("Tracking request loaded");
          });
        }
      },
      {
        onerror: function () {
          console.log("Tracking request error");
        }
      }
    );

    return true;
  }
};

export async function sendMautic(store) {
  const Cycle = store.getters.getCycle;
  const AllCycles = store.getters.getAllCycles;
  const currentOrganization = store.getters.getOrganization;
  const Objectives = store.getters.getObjectives;
  const Members = store.getters.getMembers;
  const currentUser = store.getters.currentUser;
  const currentToken = JwtService.getToken();
  const loginLogs = await fetchLoginLogs(currentUser.id, currentToken);

  const Organization =
    currentOrganization instanceof Array
      ? currentOrganization[0]
      : currentOrganization;

  const ObjectiveQuantity = (Obj, okr) => {
    Obj.forEach(element => {
      okr.editedAt =
        new Date(element.edited_at) > new Date(okr.editedAt)
          ? element.edited_at
          : okr.editedAt;

      if (element.type == "objective") {
        okr.count++;
        if (element.subObjectives && element.subObjectives.length) {
          return okr.count + ObjectiveQuantity(element.subObjectives, okr);
        } else {
          okr.empty++;
        }
      }
      return okr.count;
    });
  };

  const obtenerFechaMaxima = loginLogs => {
    let fechaMaxima = null;
    loginLogs.forEach(loginLog => {
      const fechaActual = new Date(loginLog.logged_at);
      if (!fechaMaxima || fechaActual > fechaMaxima) {
        fechaMaxima = fechaActual;
      }
    });
    return format_date(fechaMaxima);
  };

  const okr = {
    count: 0,
    empty: 0,
    editedAt: "1922-06-20T03:00:00.000Z"
  };

  ObjectiveQuantity(Objectives, okr);

  mautic.pageView(
    {
      email: currentUser.email,
      firstname: currentUser.name,
      lastname: currentUser.lastname,
      phone: currentUser.cellphone,
      company: Organization.name,
      propietario_egg_okr: Organization.owner_id === currentUser.id ? 1 : -1,
      usuarios_egg_okr: Members.length,
      rol: Organization.role,
      companyindustry: translate(
        `industria.${Organization.industry}`, {}, 'es'
      ),
      tamano: Organization.employees,
      egg_okr_id: Organization.organization,
      ciclo_actuales: AllCycles.length,
      id_ciclo_actual: Cycle.id,
      inicio_ciclo_actual: format_date(Cycle.start_at),
      fin_ciclo_actual: format_date(Cycle.end_at),
      departamentoarea: translate(
        `userDepartment.${Organization.user_department}`, {}, 'es'
      ),
      nivel_jerarquico: translate(
        `userHierarchyLevel.${Organization.user_hierarchy_level}`, {}, 'es'
      ),
      tipo_de_organizacion:
        Organization.organizationsType != null
          ? translate(`organizationsType.${Organization.organizationsType}`, {}, 'es')
          : "",
      estado_ciclo_actual: Cycle.status,
      nombre_ciclo_actual: Cycle.name,
      total_objetivos: okr.count,
      objetivos_vacios: okr.empty,
      ultima_actualizacion: okr.editedAt
        ? format_date(okr.editedAt)
        : okr.createdAt
          ? format_date(okr.createdAt)
          : "",
      /* ultimo_login: obtenerFechaMaxima(loginLogs), */
      ultimo_login: format_date(new Date()),
      planificado:
        okr.count === 0 ? 0 : Math.round((1 - okr.empty / okr.count) * 100)
    },
    currentUser.id === 19 || currentUser.id === 101
  );
}

export default mautic;
