import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import JwtService from "@/core/services/JwtService";

interface AcceptOrganizationInvitationPayload {
  organizationId: number;
  choose: string;
}
@Module
export default class ObjectiveModule extends VuexModule {
  organization = null;
  currentOrganizationId = 0;
  organizationToRejected = 0;
  organizations = [];
  loadingOrganizations = false;

  get getOrganizationToRejected(): number {
    return this.organizationToRejected;
  }

  get getOrganization(): {} | null {
    return this.organization;
  }

  get getLoadingOrganizations(): boolean {
    return this.loadingOrganizations;
  }

  get getCurrentOrganizationId(): number {
    return this.currentOrganizationId;
  }

  get getOrganizations(): Array<{}> {
    return this.organizations;
  }

  // Action
  @Action
  [Actions.LOAD_ORGANIZATION](payload) {
    const { id, organization } = payload;
    const currentToken = JwtService.getToken();
    if (currentToken != null && organization == null && id != null) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/${id}`;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 401) {
            throw new Error("resourceNotFound");
          }

          return res.json();
        })
        .then(data => {
          this.context.commit(Mutations.SET_ORGANIZATION, data.result);
          return data.result[0];
        })
        .catch(error => {
          console.log("[error]", error);
        });
    } else {
      this.context.commit(Mutations.SET_ORGANIZATION, [organization]);
    }
  }

  @Action
  async [Actions.ACCEPT_ORGANIZATION_INVITATION](payload: AcceptOrganizationInvitationPayload) {
    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/respondinvitation/`;
    const currentToken = JwtService.getToken();
    const body = {
      organization: payload.organizationId,
      answer: payload.choose,
    };

    if (!currentToken) {
      throw new Error('No token found');
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // Maneja la respuesta aquí si es necesario
      return data;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      throw error;
    }
  }


  @Action
  [Actions.LOAD_ALL_ORGANIZATIONS]() {
    this.context.commit(Mutations.SET_LOADING_ORGANIZATIONS, true);

    const user = this.context.getters.currentUser;

    const url =
      `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/bymember/` +
      user.id;
    const currentToken = JwtService.getToken();

    if (currentToken) {
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          const compareNames = (a, b) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: "base" });

          const org = data.organizations.sort(compareNames);
          this.context.dispatch(Actions.FETCH_ORGANIZATIONS, org);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  @Action
  [Actions.FETCH_ORGANIZATIONS](payload) {
    this.context.commit(Mutations.SETEO_ORGANIZATIONS, payload);
    this.context.commit(Mutations.SET_LOADING_ORGANIZATIONS, false);
  }

  @Action
  [Actions.ORGANIZATIONS_REJECT](payload) {
    this.context.commit(Mutations.SET_ORGANIZATIONS_REJECT, payload);
  }

  // Mutation
  @Mutation
  [Mutations.SET_ORGANIZATIONS_REJECT](payload) {
    console.log('=====SET_ORGANIZATIONS_REJECT======', payload);

    this.organizationToRejected = payload;
  }

  @Mutation
  [Mutations.SET_ORGANIZATION](payload) {
    this.organization = payload;
    this.currentOrganizationId = payload[0].id;
  }

  @Mutation
  [Mutations.SETEO_ORGANIZATIONS](payload) {
    this.organizations = payload;
  }

  @Mutation
  [Mutations.SET_LOADING_ORGANIZATIONS](payload: boolean) {
    this.loadingOrganizations = payload;
  }
}
