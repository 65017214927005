import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { COMMITMENT } from "@/components/app/Objectives/types";

@Module
export default class TaskModule extends VuexModule {
  commitments = [];
  fetching = false;
  changeHeight = false;

  get getCommitments(): Array<{}> {
    return this.commitments;
  }

  get getChangeHeight(): boolean {
    return this.changeHeight;
  }

  get getCommitmentsFetching(): boolean {
    return this.fetching;
  }

  // Action
  @Action
  [Actions.FETCH_COMMITMENTS]() {
    const okr = this.context.getters.getSelectedObjectiveCamelCase;
    const currentToken = JwtService.getToken();

    const url =
      `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/tasks/` +
      okr.id;

    if (currentToken != null && okr.id != null) {
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          const { result: tasks } = data;
          if (!tasks || !tasks.length) {
            this.context.commit(Mutations.SET_COMMITMENTS, []);
            return;
          }
          this.context.commit(Mutations.SET_COMMITMENTS, tasks);
        })
        .catch(error => {
          console.log("[error]", error);
        });
    }
  }

  @Action
  [Actions.ADD_COMMITMENT](commitment: COMMITMENT) {
    const currentToken = JwtService.getToken();
    const user = this.context.getters.currentUser;
    const okr = this.context.getters.getSelectedObjectiveCamelCase;

    const newCommitment = {
      ...commitment,
      endAt: commitment.end_at,
      startAt: commitment.start_at,
      user: user.id,
      okr: okr.id
    };

    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/tasks`;

    if (currentToken) {
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(newCommitment)
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          this.context.dispatch(Actions.FETCH_COMMITMENTS);
        })
        .catch(error => {
          console.log(`error`, error);
        });
    }
  }

  @Action
  [Actions.EDIT_COMMITMENT](commitment: COMMITMENT) {
    const currentToken = JwtService.getToken();

    const newCommitment = {
      ...commitment,
      endAt: commitment.end_at,
      startAt: commitment.start_at
    };

    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/tasks`;

    if (currentToken) {
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(newCommitment)
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          this.context.dispatch(Actions.FETCH_COMMITMENTS);
        })
        .catch(error => {
          console.log(`error`, error);
        });
    }
  }

  @Action
  [Actions.EDIT_COMMITMENT_CHECK]({ id, status }) {
    const currentToken = JwtService.getToken();

    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${
      process.env.VUE_APP_BACKEND_PORT
    }/tasks/${id}/toggler/${status ? 1 : 0}`;

    if (currentToken) {
      return fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => res.json())
        .catch(error => console.log(`error`, error));
    }
  }

  @Action
  [Actions.DELETE_COMMITMENT](id: number) {
    const currentToken = JwtService.getToken();

    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/tasks/${id}`;

    if (currentToken) {
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => res.json())
        .then(() => {
          this.context.dispatch(Actions.FETCH_COMMITMENTS);
        })
        .catch(error => console.log(`error`, error));
    }
  }

  @Action
  [Actions.FETCH_CHANGE_HEIGHT](payload) {
    this.context.commit(Mutations.SET_CHANGE_HEIGHT, payload);
  }

  // Mutation

  @Mutation
  [Mutations.SET_COMMITMENTS](payload) {
    this.commitments = payload;
    this.fetching = false;
  }

  @Mutation
  [Mutations.SET_COMMITMENTS_FETCHING](payload: boolean) {
    this.fetching = payload;
  }

  @Mutation
  [Mutations.SET_CHANGE_HEIGHT](payload) {
    this.changeHeight = payload;
  }
}
