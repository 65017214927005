import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class TaskModule extends VuexModule {
  tasks = [];

  get getAllTasks(): Array<{}> {
    return this.tasks;
  }

  // Action

  @Action
  [Actions.FETCH_TASKS](payload) {
    this.context.commit(Mutations.SET_TASKS, payload);
  }

  // Mutation

  @Mutation
  [Mutations.SET_TASKS](payload) {
    this.tasks = payload;
  }
}
