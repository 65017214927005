import Cookies from "js-cookie";

const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  let token = window.localStorage.getItem(ID_TOKEN_KEY);
  if (!token) {
    token = Cookies.get(ID_TOKEN_KEY);
  }
  return token;
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  if (!token || typeof token === undefined) {
    console.log("setting nothing");
    return;
  }
  Cookies.set(ID_TOKEN_KEY, token);
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  Cookies.remove(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
