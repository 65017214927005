import { Objective } from "../../components/app/Objectives/types";
import isEqual from "lodash/isEqual";

interface MyObject {
  id: number;
  name: string;
  value: number;
  subObjectives?: MyObject[];
}

interface DiffObject {
  key: string;
  oldValue: any;
  newValue: any;
}

interface DiffElement {
  id: number;
  diff: DiffObject[];
}

export function findChangedObject(
  oldValues: MyObject[],
  newValues: MyObject[]
): [MyObject, MyObject | undefined] | null {
  const idToObjectMap = new Map<number, MyObject>();
  for (const obj of newValues) {
    idToObjectMap.set(obj.id, obj);
  }

  const keys = oldValues.length > 0 ? Object.keys(oldValues[0]) : [];

  for (let i = 0; i < oldValues.length; i++) {
    const obj1 = oldValues[i];
    const obj2 = idToObjectMap.get(obj1.id);
    if (!obj2) {
      return null;
    }
    if (obj1.subObjectives || obj2.subObjectives) {
      const subObj1 = obj1.subObjectives || [];
      const subObj2 = obj2.subObjectives || [];
      if (subObj1.length !== subObj2.length) {
        return [obj1, obj2];
      }
      const subResult = findChangedObject(subObj1, subObj2);
      if (subResult) {
        return subResult;
      }
    }
    for (const key of keys) {
      const value1 = obj1[key];
      const value2 = obj2[key];
      if (key === 'user') {
        if (value1.id !== value2.id) {
          return [obj1, obj2];
        }
      } else if (typeof value1 === 'object' && value1 !== null) {
        const objectResult = findChangedObject([value1], [value2]);
        if (objectResult) {
          return objectResult;
        }
      } else if (value1 !== value2) {
        return [obj1, obj2];
      }
    }
  }
  return null;
}

export function getObjectDiffs(
  obj1: any,
  obj2: any,
  diffs: DiffObject[] = []
): DiffElement {
  const keys = Object.keys(obj1);
  keys.forEach(key => {
    if (key === "edited_at") {
      return;
    }

    const desc = Object.getOwnPropertyDescriptor(obj1, key);
    if (desc) {
      const value1 = desc.value;
      const value2 = obj2[key];

      if (key === "user") {
        if (value1.id !== value2.id) {
          diffs.push({
            key,
            oldValue: value1,
            newValue: value2
          });
        }
      } else if (key === "subObjectives") {
        // Check if the length of the arrays is different
        if (value1.length !== value2.length) {
          let addedObject = value2.find((obj2: any) => !value1.some((obj1: any) => obj1.id === obj2.id));
          // If addedObject is undefined, it means an object was removed from value1
          if (!addedObject) {
            addedObject = value1.find((obj1: any) => !value2.some((obj2: any) => obj2.id === obj1.id));
          }
          diffs.push({
            key,
            oldValue: addedObject ? null : 'Object removed',
            newValue: addedObject
          });
        } /* else {
          // If the length is the same, check for differences in the elements
          value1.forEach((subObj1: any, index: number) => {
            const subObj2 = value2[index];
            getObjectDiffs(subObj1, subObj2, diffs);
          });
        } */
      } else if (typeof value1 === "object" && value1 !== null) {
        getObjectDiffs(value1, value2, diffs);
      } else if (value1 !== value2) {
        diffs.push({
          key,
          oldValue: value1,
          newValue: value2
        });
      }
    }
  });
  return { id: obj1.id, diff: diffs };
}

export function flattenObjectives(
  objectives: Objective[],
  list: Objective[] = []
): Objective[] {
  for (const objective of objectives) {
    list.push(objective);
    if (objective.subObjectives.length > 0) {
      flattenObjectives(objective.subObjectives, list);
    }
  }
  return list;
}

export function areObjectivesEqual(obj1: Objective, obj2: Objective) {
  return isEqual(obj1, obj2);
}

export function findObjectById(
  objectives: Objective[],
  list: Objective[] = []
): Objective[] {
  for (const objective of objectives) {
    list.push(objective);
    if (objective.subObjectives.length > 0) {
      flattenObjectives(objective.subObjectives, list);
    }
  }
  return list;
}
