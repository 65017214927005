import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { NOTE } from "@/components/app/Objectives/types";

@Module
export default class TaskModule extends VuexModule {
  notes = [];
  fetching = false;

  get getNotes(): Array<{}> {
    return this.notes;
  }

  get getNotesFetching(): boolean {
    return this.fetching;
  }

  // Action
  @Action
  [Actions.FETCH_NOTES]() {
    const currentToken = JwtService.getToken();
    const okr = this.context.getters.getSelectedObjectiveCamelCase;
    const url =
      `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/notes/` +
      okr.id;

    if (currentToken != null && okr.id != null) {
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 400) {
            throw new Error("generalError");
          }
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(data => {
          const { result: notes } = data;

          if (!notes || !notes.length) {
            this.context.commit(Mutations.SET_NOTES, []);
            return;
          }
          this.context.commit(Mutations.SET_NOTES, notes);
        })
        .catch(() => {
          this.context.commit(Mutations.SET_NOTE_FETCHING, false);
        });
    }
  }

  @Action
  [Actions.EDIT_NOTE](note: NOTE) {
    const currentToken = JwtService.getToken();

    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/notes`;

    if (currentToken) {
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(note)
      })
        .then(res => {
          res.json();
          this.context.dispatch(Actions.FETCH_NOTES);
        })
        .catch(() => {
          this.context.commit(Mutations.SET_NOTE_FETCHING, false);
        });
    }
  }

  @Action
  [Actions.ADD_NOTE](note) {
    const currentToken = JwtService.getToken();
    const user = this.context.getters.currentUser;
    const okr = this.context.getters.getSelectedObjectiveCamelCase;

    const newNote = {
      description: note || "",
      okr: okr.id,
      user: user.id
    };

    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/notes`;

    if (currentToken) {
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(newNote)
      })
        .then(res => {
          res.json();
          this.context.dispatch(Actions.FETCH_NOTES);
        })
        .catch(() => {
          this.context.commit(Mutations.SET_NOTE_FETCHING, false);
        });
    }
  }

  @Action
  [Actions.DELETE_NOTE](note) {
    const currentToken = JwtService.getToken();

    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/notes/${note.id}`;

    if (currentToken) {
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => res.json())
        .then(() => {
          this.context.dispatch(Actions.FETCH_NOTES);
        })
        .catch(() => {
          this.context.commit(Mutations.SET_NOTE_FETCHING, false);
        });
    }
  }

  // Mutation

  @Mutation
  [Mutations.SET_NOTES](payload) {
    this.notes = payload;
    this.fetching = false;
  }

  @Mutation
  [Mutations.SET_NOTE_FETCHING](payload) {
    this.fetching = payload;
  }
}
