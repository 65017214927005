import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import JwtService from "@/core/services/JwtService";

@Module
export default class TaskModule extends VuexModule {

  changingPeriodState = false;

  get getChangingPeriodState(): boolean {
    return this.changingPeriodState;
  }

  // Action

  @Action
  [Actions.FETCH_PERIOD]() {

    const organizationId = this.context.getters.getCurrentOrganizationId;
    const currentToken = JwtService.getToken();
    const url =
      `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/periods/organization/` +
      organizationId;

    if (organizationId && currentToken) {

      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          this.context.dispatch(Actions.FETCH_CYCLES, data.result);
        })
    }
  }

  @Action
  [Actions.SAVE_PERIOD](cycle) {
    this.context.commit(Mutations.SET_CHANGING_STATE, true);

    const action = cycle.id ? "PUT" : "POST";
    const organizationId = this.context.getters.getCurrentOrganizationId;
    const currentToken = JwtService.getToken();

    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/periods`;

    cycle.organization = organizationId;

    if (currentToken && organizationId) {

      return fetch(url, {
        method: action,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(cycle)
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(() => {
          this.context.dispatch(Actions.FETCH_PERIOD);
          this.context.commit(Mutations.SET_CHANGING_STATE, false);
        })
    }
  }

  // Mutation

  @Mutation
  [Mutations.SET_PERIOD](payload) {
    // this.tasks = payload;
  }

  @Mutation
  [Mutations.SET_CHANGING_STATE](payload: boolean) {
    this.changingPeriodState = payload;
  }
}
