import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import JwtService from "@/core/services/JwtService";

@Module
export default class TaskModule extends VuexModule {
  members = [];

  get getMembers(): Array<{}> {
    return this.members;
  }

  // Action
  @Action
  [Actions.FETCH_MEMBERS](payload) {
    const { id } = payload;
    const currentToken = JwtService.getToken();
    const url =
      `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/members/` +
      id;

    if (currentToken != null && id != null) {
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 400) {
            throw new Error("generalError");
          }
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(data => {
          const { members } = data;
          if (!members || !members.length) {
            return;
          }
          this.context.commit(Mutations.SET_MEMBERS, members);
          return members;
        })
        .catch(error => {
          console.log("[error]", error);
        });
    }
  }

  @Action
  [Actions.UPDATE_MEMBER](payload: {
    user_organization_id: number;
    hierarchy: string;
    area: string;
    update: boolean;
  }) {
    const organizationId = this.context.getters.getCurrentOrganizationId;
    const userId = this.context.getters.currentUser;

    const currentToken = JwtService.getToken();
    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/member`;

    const body = {
      user_organization_id: payload.user_organization_id,
      hierarchy: payload.hierarchy,
      area: payload.area
    };

    if (currentToken != null) {
      return fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(body)
      })
        .then(res => {
          if (res.status === 400) {
            throw new Error("generalError");
          }
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(() => {
          if (payload.update) {
            this.context.dispatch(Actions.FETCH_MEMBERS, {
              id: organizationId
            });
          } else {
            this.context.dispatch(Actions.LOAD_ALL_ORGANIZATIONS, userId.id);
          }
        })
        .catch(error => {
          console.log("[error]", error);
        });
    }
  }

  // Mutation

  @Mutation
  [Mutations.SET_MEMBERS](payload) {
    this.members = payload;
  }
}
